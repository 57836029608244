define('ember-inplace-edit/components/ember-inplace-edit', ['exports', 'ember', 'ember-inplace-edit/templates/components/ember-inplace-edit'], function (exports, _ember, _emberInplaceEditTemplatesComponentsEmberInplaceEdit) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberInplaceEditTemplatesComponentsEmberInplaceEdit['default'],

    didInsertElement: function didInsertElement() {
      var _this = this;
      if (this.get('type') === 'input') {
        $('body').on('focusout', "#" + this.get("elementId") + " input", function () {
          _this.send('doneEditing');
        });
      } else {
        $('body').on('focusout', "#" + this.get("elementId") + " textarea", function () {
          _this.send('doneEditing');
        });
      }
      this.focus();
    },

    keyPress: function keyPress(event) {
      if (event.keyCode == 13) {
        this.toggleProperty('isEditing');
        this.sendAction();
      }
    },

    mouseEnter: function mouseEnter() {
      $("#" + this.get("elementId") + " .edit").removeClass('hide');
    },

    mouseLeave: function mouseLeave() {
      $("#" + this.get("elementId") + " .edit").addClass('hide');
    },

    height: null,

    focus: (function () {
      var _this = this;
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        if (_this.get('isEditing')) {
          if (_this.get('type') === 'input') {
            $("#" + _this.get("elementId") + " input").focus();
          } else {
            $("#" + _this.get("elementId") + " textarea").css('height', _this.get('height'));
            $("#" + this.get("elementId") + " textarea").focus();
          }
        } else {
          // Set height of editable div
          _this.set('height', $("#" + _this.get("elementId")).parent().css('height'));
        }
      });
    }).observes('isEditing'),

    isTypeInput: (function () {
      return this.get('type') === "input";
    }).property('type'),

    displayPlaceholder: (function () {
      var text = this.get('text');
      return text === undefined || text === null || text === "";
    }).property('text'),

    actions: {
      toggleEditing: function toggleEditing() {
        this.toggleProperty('isEditing');
      },
      doneEditing: function doneEditing() {
        if (this.get('isEditing') == true) {
          this.toggleProperty('isEditing');
          this.sendAction();
        }
      }
    }
  });
});