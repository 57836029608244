define('ember-idx-utils/config', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  /*
  Maintain a list of configuration within an Ember Namespace,
  */
  exports['default'] = _ember['default'].Namespace.extend({
    _configs: _ember['default'].Object.create(),
    getConfig: function getConfig(name) {
      var config;
      config = this._configs.get(name);
      return config;
    },
    addConfig: function addConfig(name, config) {
      var defaultConfig, newConfig;
      defaultConfig = this._configs.get('default');
      newConfig = _ember['default'].Object.create(config);
      newConfig = _ember['default'].$.extend(true, newConfig, defaultConfig);
      return this._configs.set(name, newConfig);
    }
  });
});