define('ember-idx-utils/utils/delay', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var delay = function delay(ms) {
    ms = ms || 1500;
    return new _ember['default'].RSVP.Promise(function (resolve) {
      _ember['default'].run.later(this, resolve, ms);
    });
  };

  exports['default'] = delay;
});