define('ember-css-modules/mixins/controller-mixin', ['exports', 'ember', 'ember-getowner-polyfill'], function (exports, _ember, _emberGetownerPolyfill) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    styles: _ember['default'].computed(function () {
      var key = this._debugContainerKey;
      if (!key) {
        return;
      }

      return (0, _emberGetownerPolyfill['default'])(this)._lookupFactory('styles:' + key.split(':')[1]);
    })
  });
});