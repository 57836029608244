define('ember-perfect-scroll/components/perfect-scroll', ['exports', 'ember', 'ember-perfect-scroll/templates/components/perfect-scroll'], function (exports, _ember, _emberPerfectScrollTemplatesComponentsPerfectScroll) {
  'use strict';

  var $ = _ember['default'].$;
  var on = _ember['default'].on;
  var _get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var guidFor = _ember['default'].guidFor;
  var warn = _ember['default'].warn;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberPerfectScrollTemplatesComponentsPerfectScroll['default'],

    // Internal id for element
    scrollId: null,

    // Perfect scrollbar related settings
    wheelSpeed: 1,
    wheelPropagation: false,
    swipePropagation: true,
    minScrollbarLength: null,
    maxScrollbarLength: null,
    useBothWheelAxes: false,
    useKeyboard: true,
    suppressScrollX: false,
    suppressScrollY: false,
    scrollTop: 0,
    scrollLeft: 0,
    scrollXMarginOffset: 0,
    scrollYMarginOffset: 0,
    includePadding: false,

    _renderPerfectScroll: on('didInsertElement', function () {
      var _this = this;

      run.schedule("afterRender", this, function () {
        var elementId = '#' + _get(_this, 'eId');
        window.Ps.initialize($(elementId)[0], _this._getOptions());
      });
    }),

    eId: computed('scrollId', {
      get: function get() {
        if (isEmpty(_get(this, 'scrollId'))) {
          set(this, 'scrollId', 'perfect-scroll-' + guidFor(this));
        }
        return _get(this, 'scrollId');
      }
    }).readOnly(),

    _getOptions: function _getOptions() {
      return {
        wheelSpeed: _get(this, 'wheelSpeed'),
        wheelPropagation: _get(this, 'wheelPropagation'),
        swipePropagation: _get(this, 'swipePropagation'),
        minScrollbarLength: _get(this, 'minScrollbarLength'),
        maxScrollbarLength: _get(this, 'maxScrollbarLength'),
        useBothWheelAxes: _get(this, 'useBothWheelAxes'),
        useKeyboard: _get(this, 'useKeyboard'),
        suppressScrollX: _get(this, 'suppressScrollX'),
        suppressScrollY: _get(this, 'suppressScrollY'),
        scrollXMarginOffset: _get(this, 'scrollXMarginOffset'),
        scrollYMarginOffset: _get(this, 'scrollYMarginOffset'),
        includePadding: _get(this, 'includePadding'),
        scrollTop: _get(this, 'scrollTop'),
        scrollLeft: _get(this, 'scrollLeft')
      };
    }
  });
});