define('ember-collapsible-panel/components/cp-panel-toggle', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'a',
    classNames: ['cp-Panel-toggle'],
    classNameBindings: ['isOpen:cp-is-open'],

    _cpPanelToggle: true,

    // So taps register in iOS
    attributeBindings: ['href'],
    href: '#',

    isOpen: _ember['default'].computed.readOnly('panelComponent.isOpen'),

    click: function click(e) {
      e.preventDefault();
      this.get('panelComponent').handleToggle();
    },

    registerWithPanel: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', function () {
        var panel = _this.nearestWithProperty('_cpPanel');
        _this.set('panelComponent', panel);
      });
    })
  });
});