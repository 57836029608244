define('ember-idx-utils/mixin/with-config', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    configName: _ember['default'].computed(function () {
      var config;
      config = this.nearestWithProperty('configName');
      if (config) {
        return config.get('configName');
      } else {
        return 'default';
      }
    }),
    config: _ember['default'].computed('configName', function () {
      return _ember['default'].IdxConfig.getConfig(this.get('configName'));
    })
  });
});