define('ember-collapsible-panel/components/cp-panel-body', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['cp-Panel-body'],
    classNameBindings: ['isOpen:cp-is-open'],

    panelComponent: null,

    _cpPanelBody: true,

    dependencyChecker: _ember['default'].inject.service(),
    shouldAnimate: _ember['default'].computed.and('dependencyChecker.hasLiquidFire', 'panelComponent.shouldAnimate'),

    isOpen: _ember['default'].computed.readOnly('panelComponent.isOpen'),

    registerWithPanel: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', function () {
        var panel = _this.nearestWithProperty('_cpPanel');
        _this.set('panelComponent', panel);
      });
    })
  });
});