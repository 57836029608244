define('ember-cli-fake-server/lib/json-utils', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.stringifyJSON = stringifyJSON;
  exports.jsonFromRequest = jsonFromRequest;

  function stringifyJSON() {
    var json = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

    return JSON.stringify(json);
  }

  function jsonFromRequest(request) {
    var json = {};
    if (request.requestBody) {
      try {
        json = JSON.parse(request.requestBody);
      } catch (e) {
        _ember['default'].Logger.warn('[FakeServer] Failed to parse json from request.requestBody "' + request.requestBody + '" (error: ' + e + ')');
      }
    }

    return json;
  }
});