define('ember-metrics/metrics-adapters/mixpanel', ['exports', 'ember', 'ember-metrics/utils/can-use-dom', 'ember-metrics/utils/object-transforms', 'ember-metrics/metrics-adapters/base'], function (exports, _ember, _emberMetricsUtilsCanUseDom, _emberMetricsUtilsObjectTransforms, _emberMetricsMetricsAdaptersBase) {
  'use strict';

  var assert = _ember['default'].assert;
  var $ = _ember['default'].$;
  var get = _ember['default'].get;
  var without = _emberMetricsUtilsObjectTransforms['default'].without;
  var compact = _emberMetricsUtilsObjectTransforms['default'].compact;
  var isPresent = _emberMetricsUtilsObjectTransforms['default'].isPresent;

  exports['default'] = _emberMetricsMetricsAdaptersBase['default'].extend({
    toStringExtension: function toStringExtension() {
      return 'Mixpanel';
    },

    init: function init() {
      var config = get(this, 'config');
      var token = config.token;

      assert('[ember-metrics] You must pass a valid `token` to the ' + this.toString() + ' adapter', token);

      if (_emberMetricsUtilsCanUseDom['default']) {
        /* jshint ignore:start */
        (function (e, b) {
          if (!b.__SV) {
            var a, f, i, g;window.mixpanel = b;b._i = [];b.init = function (a, e, d) {
              function f(b, h) {
                var a = h.split(".");2 == a.length && (b = b[a[0]], h = a[1]);b[h] = function () {
                  b.push([h].concat(Array.prototype.slice.call(arguments, 0)));
                };
              }var c = b;"undefined" !== typeof d ? c = b[d] = [] : d = "mixpanel";c.people = c.people || [];c.toString = function (b) {
                var a = "mixpanel";"mixpanel" !== d && (a += "." + d);b || (a += " (stub)");return a;
              };c.people.toString = function () {
                return c.toString(1) + ".people (stub)";
              };i = "disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
              for (g = 0; g < i.length; g++) f(c, i[g]);b._i.push([a, e, d]);
            };b.__SV = 1.2;a = e.createElement("script");a.type = "text/javascript";a.async = !0;a.src = "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL ? MIXPANEL_CUSTOM_LIB_URL : "file:" === e.location.protocol && "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//) ? "https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js" : "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";f = e.getElementsByTagName("script")[0];f.parentNode.insertBefore(a, f);
          }
        })(document, window.mixpanel || []);
        mixpanel.init(token);
        /* jshint ignore:end */
      }
    },

    identify: function identify() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var compactedOptions = compact(options);
      var distinctId = compactedOptions.distinctId;

      var props = without(compactedOptions, 'distinctId');

      if (isPresent(props)) {
        window.mixpanel.identify(distinctId, props);
      } else {
        window.mixpanel.identify(distinctId);
      }
    },

    trackEvent: function trackEvent() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var compactedOptions = compact(options);
      var event = compactedOptions.event;

      var props = without(compactedOptions, 'event');

      if (isPresent(props)) {
        window.mixpanel.track(event, props);
      } else {
        window.mixpanel.track(event);
      }
    },

    alias: function alias() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var compactedOptions = compact(options);
      var alias = compactedOptions.alias;
      var original = compactedOptions.original;

      if (original) {
        window.mixpanel.alias(alias, original);
      } else {
        window.mixpanel.alias(alias);
      }
    },

    willDestroy: function willDestroy() {
      $('script[src*="mixpanel"]').remove();
      delete window.mixpanel;
    }
  });
});