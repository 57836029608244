define('ember-cli-fake-server/lib/logging', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.unhandledRequest = unhandledRequest;
  exports.handledRequest = handledRequest;

  function unhandledRequest(verb, path, request) {
    var msg = '[FakeServer] received unhandled request for ' + verb + ' ' + path;
    _ember['default'].Logger.error(msg, request);
    throw new Error(msg);
  }

  function handledRequest(verb, path) {
    _ember['default'].Logger.log('[FakeServer] handled: ' + verb + ' ' + path);
  }
});