define('ember-body-class/mixins/body-class', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    actions: {
      loading: function loading() /* transition, route */{
        if (!_ember['default'].$) {
          // No jquery in fastboot
          return true;
        }

        _ember['default'].$('body').addClass("loading");

        this.router.on('didTransition', function () {
          _ember['default'].$('body').removeClass("loading");
        });

        return true;
      },

      error: function error() /* error, transition */{
        if (!_ember['default'].$) {
          // No jquery in fastboot
          return true;
        }

        _ember['default'].$('body').addClass("error");

        this.router.on('didTransition', function () {
          _ember['default'].$('body').removeClass("error");
        });

        return true;
      }
    }
  });
});