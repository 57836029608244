define('ember-cli-font-awesome/helpers/fa-icon', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  var FA_PREFIX = /^fa\-.+/;

  var warn = _ember['default'].Logger.warn;
  var typeOf = _ember['default'].typeOf;
  var isArray = _ember['default'].isArray;

  /**
   * Handlebars helper for generating HTML that renders a FontAwesome icon.
   *
   * @param  {String} name   The icon name. Note that the `fa-` prefix is optional.
   *                         For example, you can pass in either `fa-camera` or just `camera`.
   * @param  {Object} params Options passed to helper.
   * @return {Ember.Handlebars.SafeString} The HTML markup.
   */
  var faIcon = function faIcon(name) {
    var _ref = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var classNames = _ref.classNames;
    var tagName = _ref.tagName;
    var ariaHidden = _ref.ariaHidden;
    var title = _ref.title;
    var spin = _ref.spin;
    var pulse = _ref.pulse;
    var flip = _ref.flip;
    var rotate = _ref.rotate;
    var lg = _ref.lg;
    var x = _ref.x;
    var size = _ref.size;
    var fixedWidth = _ref.fixedWidth;
    var listItem = _ref.listItem;
    var border = _ref.border;
    var pull = _ref.pull;
    var stack = _ref.stack;
    var inverse = _ref.inverse;

    if (typeOf(name) !== 'string') {
      var message = "fa-icon: no icon specified";
      warn(message);
      return _ember['default'].String.htmlSafe(message);
    }

    if (classNames === undefined) {
      classNames = [];
    }

    if (!isArray(classNames)) {
      classNames = [classNames];
    }

    classNames.push("fa");

    if (!name.match(FA_PREFIX)) {
      name = 'fa-' + name;
    }

    classNames.push(name);

    if (spin) {
      classNames.push("fa-spin");
    }

    if (pulse) {
      classNames.push("fa-pulse");
    }

    if (flip) {
      classNames.push('fa-flip-' + flip);
    }

    if (rotate) {
      classNames.push('fa-rotate-' + rotate);
    }

    if (lg) {
      warn('fa-icon: the \'lg\' parameter is deprecated. Use \'size\' instead. I.e. {{fa-icon size="lg"}}');
      classNames.push("fa-lg");
    }

    if (x) {
      warn('fa-icon: the \'x\' parameter is deprecated. Use \'size\' instead. I.e. {{fa-icon size="' + x + '"}}');
      classNames.push('fa-' + x + 'x');
    }

    if (size) {
      if (typeOf(size) === "string" && size.match(/^\d+$/)) {
        size = Number(size);
      }

      if (typeOf(size) === "number") {
        classNames.push('fa-' + size + 'x');
      } else {
        classNames.push('fa-' + size);
      }
    }

    if (fixedWidth) {
      classNames.push("fa-fw");
    }

    if (listItem) {
      classNames.push("fa-li");
    }

    if (pull) {
      classNames.push("pull-" + pull);
    }

    if (border) {
      classNames.push("fa-border");
    }

    if (stack) {
      if (typeOf(stack) === "string" && stack.match(/^\d+$/)) {
        size = Number(stack);
      }

      if (typeOf(stack) === "number") {
        classNames.push('fa-stack-' + stack + 'x');
      } else {
        classNames.push('fa-stack-' + stack);
      }
    }

    if (inverse) {
      classNames.push("fa-inverse");
    }

    var showAriaHidden = ariaHidden === undefined || ariaHidden;

    tagName = tagName || 'i';

    var htmlClass = 'class="' + classNames.join(" ") + '"';
    var htmlTitle = title ? 'title="' + title + '"' : "";
    var htmlAriaHidden = showAriaHidden ? 'aria-hidden="true"' : "";

    var html = '<' + tagName + ' ' + htmlClass + ' ' + htmlTitle + ' ' + htmlAriaHidden + '></' + tagName + '>';

    // removes extra whitespaces
    return html.replace(/\s+/g, " ");
  };

  exports.faIcon = faIcon;
  // jshint ignore:line
  exports['default'] = _ember['default'].Helper.helper(function faIconHelper(_ref2, params) {
    var _ref22 = _slicedToArray(_ref2, 1);

    var name = _ref22[0];

    return _ember['default'].String.htmlSafe(faIcon(name, params));
  });
});