define("ember-cli-fake-server/lib/responses", ["exports"], function (exports) {
  "use strict";

  exports.error = error;
  exports.notFound = notFound;
  exports.ok = ok;
  exports.created = created;
  exports.noContent = noContent;
  exports.unauthorized = unauthorized;

  var STATUS_CODES = {
    error: 422,
    notFound: 404,
    ok: 200,
    created: 201,
    noContent: 204,
    unauthorized: 401
  };

  exports.STATUS_CODES = STATUS_CODES;

  var RESPONSE_ALIASES = {
    ok: ['success']
  };

  exports.RESPONSE_ALIASES = RESPONSE_ALIASES;

  var jsonMimeType = { "Content-Type": "application/json" };

  function error(status, errors) {
    if (!errors) {
      errors = status;status = STATUS_CODES.error;
    }

    return [status, jsonMimeType, errors];
  }

  function notFound(status, json) {
    if (!json) {
      json = status || {};status = STATUS_CODES.notFound;
    }

    return [status, jsonMimeType, json];
  }

  function ok(status, json) {
    if (!json) {
      json = status;status = STATUS_CODES.ok;
    }

    return [status, jsonMimeType, json];
  }

  function created(status, json) {
    if (!json) {
      json = status;status = STATUS_CODES.created;
    }

    return [status, jsonMimeType, json];
  }

  function noContent(status) {
    if (!status) {
      status = STATUS_CODES.noContent;
    }

    return [status, jsonMimeType, ''];
  }

  function unauthorized(status) {
    if (!status) {
      status = STATUS_CODES.unauthorized;
    }

    return [status, jsonMimeType, ''];
  }
});