define('ember-cli-fake-server/index', ['exports', 'pretender', 'ember', 'ember-cli-fake-server/lib/responses', 'ember-cli-fake-server/lib/logging', 'ember-cli-fake-server/lib/json-utils'], function (exports, _pretender, _ember, _emberCliFakeServerLibResponses, _emberCliFakeServerLibLogging, _emberCliFakeServerLibJsonUtils) {
  'use strict';

  exports.passthroughRequest = passthroughRequest;
  exports.stubRequest = stubRequest;

  var currentServer = undefined;

  var _config = defaultConfig();

  function defaultConfig() {
    return {
      preparePath: function preparePath(path) {
        return path;
      },
      fixtureFactory: function fixtureFactory() {
        _ember['default'].Logger.warn('[FakeServer] `fixture` called but no fixture factory is registered');
      }
    };
  }

  function bindResponses(request, responseRef) {
    Object.keys(_emberCliFakeServerLibResponses.STATUS_CODES).forEach(function (key) {
      request[key] = function () {
        if (responseRef.response) {
          throw new Error('[FakeServer] Stubbed Request responded with "' + key + '" after already responding');
        }
        var response = _emberCliFakeServerLibResponses[key].apply(_emberCliFakeServerLibResponses, arguments);
        responseRef.response = response;
        return response;
      };
    });
    Object.keys(_emberCliFakeServerLibResponses.RESPONSE_ALIASES).forEach(function (key) {
      var aliases = _emberCliFakeServerLibResponses.RESPONSE_ALIASES[key];
      aliases.forEach(function (alias) {
        return request[alias] = request[key];
      });
    });
  }

  function passthroughRequest(verb, path) {
    path = _config.preparePath(path);
    _ember['default'].assert('[FakeServer] cannot passthrough request if FakeServer is not running', !!currentServer);

    currentServer[verb.toLowerCase()](path, currentServer.passthrough);
  }

  function stubRequest(verb, path, callback) {
    path = _config.preparePath(path);
    _ember['default'].assert('[FakeServer] cannot stub request if FakeServer is not running', !!currentServer);

    var boundCallback = function boundCallback(request) {
      var responseRef = {};

      bindResponses(request, responseRef);

      request.json = function () {
        return _emberCliFakeServerLibJsonUtils.jsonFromRequest(request);
      };
      request.fixture = _config.fixtureFactory;

      var context = {
        json: _emberCliFakeServerLibJsonUtils.jsonFromRequest,
        fixture: _config.fixtureFactory
      };
      Object.keys(_emberCliFakeServerLibResponses.STATUS_CODES).forEach(function (key) {
        context[key] = _emberCliFakeServerLibResponses[key];
      });
      Object.keys(_emberCliFakeServerLibResponses.RESPONSE_ALIASES).forEach(function (key) {
        var aliases = _emberCliFakeServerLibResponses.RESPONSE_ALIASES[key];
        aliases.forEach(function (alias) {
          return context[alias] = context[key];
        });
      });

      var returnValue = callback.call(context, request);
      returnValue = returnValue || responseRef.response;
      if (!returnValue) {
        throw new Error('[FakeServer] No return value for stubbed request for ' + verb + ' ' + path + '.\n         Use `request.ok(json)` or similar');
      }
      return returnValue;
    };

    currentServer[verb.toLowerCase()](path, boundCallback);
  }

  var FakeServer = {
    configure: {
      fixtureFactory: function fixtureFactory(_fixtureFactory) {
        _config.fixtureFactory = _fixtureFactory;
      },
      preparePath: function preparePath(fn) {
        _config.preparePath = fn;
      }
    },

    start: function start() {
      _ember['default'].assert('[FakeServer] Cannot start FakeServer while already started. ' + 'Ensure you call `FakeServer.stop()` first.', !FakeServer.isRunning());

      currentServer = this._currentServer = new _pretender['default']();
      currentServer.prepareBody = _emberCliFakeServerLibJsonUtils.stringifyJSON;
      currentServer.unhandledRequest = _emberCliFakeServerLibLogging.unhandledRequest;
      currentServer.handledRequest = _emberCliFakeServerLibLogging.handledRequest;
    },

    isRunning: function isRunning() {
      return !!currentServer;
    },

    stop: function stop() {
      if (!FakeServer.isRunning()) {
        _ember['default'].Logger.warn('[FakeServer] called `stop` without having started.');
        return;
      }
      currentServer.shutdown();
      currentServer = this._currentServer = null;

      _config = defaultConfig();
    }
  };

  exports['default'] = FakeServer;
});