define('ember-cli-jquery-ui/components/jqui-button/component', ['exports', 'ember', 'ember-cli-jquery-ui/mixins/jqui-widget'], function (exports, _ember, _emberCliJqueryUiMixinsJquiWidget) {
    'use strict';

    exports['default'] = _ember['default'].Component.extend(_emberCliJqueryUiMixinsJquiWidget['default'], {
        uiType: 'button',
        uiOptions: ['disabled'],
        uiEvents: [],
        tagName: 'button',
        disabled: false,
        icon: "",

        didInsertElement: function didInsertElement() {
            var _this = this;
            _ember['default'].run.next(function () {
                _this.$().button("option", "icons", {
                    primary: _this.get('icon')
                });
            });
        },

        click: function click() {
            this.sendAction();
        }
    });
});