define('ember-idx-tabs/tabs', ['exports', 'ember', 'ember-idx-utils/mixin/with-config', 'ember-idx-utils/mixin/style-bindings'], function (exports, _ember, _emberIdxUtilsMixinWithConfig, _emberIdxUtilsMixinStyleBindings) {
  //(c) 2014 Indexia, Inc.
  'use strict';

  /**
   * `{{em-tabs}}` component.
   *
   * The top level component for rendering tabs and their panes.
   *
   * Simplest usage:
   *
   * ```handlebars
   * {{#em-tabs}}
   *    {{#em-tab-list}}
   *       {{#em-tab}}ral{{/em-tab}}
   *       {{#em-tab}}Security{{/em-tab}}
   *       {{#em-tab}}Advanced{{/em=tab}}
   *    {{em-tab-list}}
   * {{/em-tabs}}
   * ```
   *
   * @class Tabs
   * @public
   */
  exports['default'] = _ember['default'].Component.extend(_emberIdxUtilsMixinWithConfig['default'], _emberIdxUtilsMixinStyleBindings['default'], {
    debug: false,
    classNameBindings: ['styleClasses'],
    styleClasses: _ember['default'].computed(function () {
      var _ref;
      return (_ref = this.get('config.tabs.tabsClasses')) != null ? _ref.join(" ") : void 0;
    }),
    styleBindings: ['height'],

    /**
     * A list of tab panels
     *
     * @property panels
     * @private
     * @type Array
     */
    panels: void 0,

    /**
     * A {{#crossLink "TabList"}}{{/crossLink}} component instance.
     *
     * @property tabList
     * @type TabList
     */
    tabList: void 0,

    /**
     * The selected tab instance.
     *
     * @property selectedTab
     * @type Tab
     * @private
     * @see Tab
     *
     */
    selected: void 0,

    /**
     * The index of the selected tab
     *
     * @property 'selected-idx'
     * @type Number
     */
    'selected-idx': 0,

    /**
     * Select the given tab.
     *
     * @method select
     * @param {Object} a tab instance to select.
     * @see selectedTab
     * @see selected-idx
     */
    select: function select(tab) {
      if (!tab) {
        return;
      }
      if (this.get('debug')) {
        _ember['default'].debug("Selecting tab: " + tab.get('index'));
      }
      this.set('selected', tab);
      return this.set('selected-idx', tab.get('index'));
    },

    /**
     * Initialize the tab panels array
     *
     * @method initTabPanels
     */
    initTabPanels: _ember['default'].on('init', function () {
      return this.set('panels', _ember['default'].ArrayProxy.create({
        content: _ember['default'].A()
      }));
    }),

    /**
     * Set the specified `TabList` instance.
     *
     * @method setTabList
     * @private
     */
    setTabList: function setTabList(tabList) {
      return this.set('tabList', tabList);
    },

    /**
     * Add the given `TabPanel` instance to the tabs panels.
     *
     * @method addTabPanel
     * @param panel {Object} The `TabPanel` instance to add.
     */
    addTabPanel: function addTabPanel(panel) {
      return this.get('panels').addObject(panel);
    },

    /**
     * Remove the given `TabPanel` instance from the tabs panels.
     *
     * @method removeTabPanel.
     * @param panel {Object} The `TabPanel` instance to remove.
     */
    removeTabPanel: function removeTabPanel(panel) {
      return this.get('panels').removeObject(panel);
    }
  });
});