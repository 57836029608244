define('ember-collapsible-panel/components/cp-panel', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({

    // Your binding to open the panel
    open: null,

    classNameBindings: [':cp-Panel', 'isOpen:cp-is-open:cp-is-closed'],

    _cpPanel: true,

    // allow caller to overwrite this property
    name: _ember['default'].computed.oneWay('elementId'),

    panelActions: _ember['default'].inject.service(),

    panelState: _ember['default'].computed('nane', function () {
      var name = this.get('name');
      return this.get('panelActions.state.' + name);
    }),

    group: _ember['default'].computed.readOnly('panelState.group'),

    isOpen: _ember['default'].computed.readOnly('panelState.isOpen'),
    isClosed: _ember['default'].computed.not('isOpen'),

    panelsWrapper: null,
    animate: true,

    _setup: _ember['default'].on('init', function () {
      var binding = _ember['default'].Binding.from('open').to('panelState.boundOpenState').oneWay();
      binding.connect(this);
    }),

    // Register with parent panels component
    _afterInsert: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', function () {
        var group = _this.nearestWithProperty('_cpPanels');
        if (group) {
          _this.get('panelState').set('group', group);
        }
      });
    }),

    shouldAnimate: _ember['default'].computed.alias('animate'),

    handleToggle: function handleToggle() {
      this.get('panelActions').toggle(this.get('name'));
    }
  });
});