define('ember-css-modules/helpers/lookup-module-styles', ['exports', 'ember'], function (exports, _ember) {
                       'use strict';

                       var _slicedToArray = (function () {
                                              function sliceIterator(arr, i) {
                                                                     var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
                                                                                            for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                                                                                                                   _arr.push(_s.value);if (i && _arr.length === i) break;
                                                                                            }
                                                                     } catch (err) {
                                                                                            _d = true;_e = err;
                                                                     } finally {
                                                                                            try {
                                                                                                                   if (!_n && _i['return']) _i['return']();
                                                                                            } finally {
                                                                                                                   if (_d) throw _e;
                                                                                            }
                                                                     }return _arr;
                                              }return function (arr, i) {
                                                                     if (Array.isArray(arr)) {
                                                                                            return arr;
                                                                     } else if (Symbol.iterator in Object(arr)) {
                                                                                            return sliceIterator(arr, i);
                                                                     } else {
                                                                                            throw new TypeError('Invalid attempt to destructure non-iterable instance');
                                                                     }
                                              };
                       })();

                       exports.lookupModuleStyles = lookupModuleStyles;

                       function lookupModuleStyles(_ref) {
                                              var _ref2 = _slicedToArray(_ref, 2);

                                              var stylesMap = _ref2[0];
                                              var localClassStyles = _ref2[1];

                                              return localClassStyles.split(' ').map(function (style) {
                                                                     return stylesMap[style];
                                              }).filter(function (style) {
                                                                     return style;
                                              }).join(' ');
                       }

                       exports['default'] = _ember['default'].Helper.helper(lookupModuleStyles);
});