define('ember-collapsible-panel/components/cp-panels', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: 'cp-Panels',
    accordion: false,
    animate: true,

    _cpPanels: true,

    name: _ember['default'].computed.oneWay('elementId')
  });
});