define('ember-introjs/helpers', ['exports', 'ember-introjs/components/intro-js', 'ember'], function (exports, _emberIntrojsComponentsIntroJs, _ember) {
  'use strict';

  if (typeof _ember['default'].Test !== 'undefined') {
    _ember['default'].runInDebug(function () {
      var nextCompleted = false;
      var currentStep;
      var introJS;

      _emberIntrojsComponentsIntroJs['default'].reopen({
        _setIntroJS: function _setIntroJS(_introJS) {
          introJS = _introJS;
          this._super(introJS);
        },
        _onExit: function _onExit() {
          this._super();
        },

        _onAfterChange: function _onAfterChange(targetElement) {
          nextCompleted = true;
          this._super(targetElement);
        },

        _setCurrentStep: function _setCurrentStep(step) {
          this._super(step);
          currentStep = this.get('currentStep');
        },

        stopWatchingTestVars: _ember['default'].on('willDestroyElement', function () {
          introJS = null;
        })
      });

      function checkNextCompleted() {
        if (!nextCompleted) {
          return wait().then(checkNextCompleted);
        } else {
          nextCompleted = false;
        }
      }

      function checkExitCompleted() {
        if ($('.introjs-overlay').length !== 0) {
          return wait().then(checkExitCompleted);
        }
      }

      _ember['default'].Test.registerAsyncHelper('introJSNext', function (app, context) {
        click($('.introjs-nextbutton'));
        return wait().then(checkNextCompleted);
      });

      _ember['default'].Test.registerAsyncHelper('introJSExit', function (app, context) {
        click($('.introjs-skipbutton'));
        return wait().then(checkExitCompleted);
      });

      _ember['default'].Test.registerAsyncHelper('introJSEnsureClosed', function () {
        if (!introJS) {
          return wait();
        }
        introJS.exit();
        return wait().then(checkExitCompleted);
      });

      _ember['default'].Test.registerAsyncHelper('introJSPrevious', function () {
        click($('.introjs-prevbutton'));
        return wait().then(checkNextCompleted);
      });

      _ember['default'].Test.registerHelper('introJSCurrentStep', function (app) {
        return currentStep;
      });
    });
  }
});